export default {
    "modal.menuCreate": "Tạo menu mới",
    "modal.menuUpdate": "Cập nhật menu",
    "modal.parentMenu": "Menu cha",
    "modal.carouselCreate": "Tạo băng chuyền",
    "modal.carouselUpdate": "Cập nhật băng chuyền",
    "modal.areYouSure": "Bạn có chắc chắn muốn {{middle}}?",
    "modal.areYouSureToThisUser":
        "Bạn có chắc chắn muốn {{middle}} người dùng này?",
    "modal.areYouSureWantToUnbind": "Bạn có chắc chắn muốn hủy liên kết?",
    "modal.areYouSureWantToUnbindThisCharacter":
        "Bạn có chắc chắn muốn hủy liên kết nhân vật này?",
    "modal.createRole": "Tạo vai trò",
    "modal.editRole": "Chỉnh sửa vai trò",
    "modal.pleaseSelectItem": "Vui lòng chọn một mục",
    "modal.createFinancialProduct": "Tạo sản phẩm tài chính",
    "modal.updateFinancialProduct": "Cập nhật sản phẩm tài chính",
    "modal.auditGood": "Kiểm tra sản phẩm",
    "modal.edit": "Chỉnh sửa",
    "modal.update": "Cập nhật",
    "modal.update_sort": "Cập nhật số thứ tự",
    "modal.create": "Tạo mới",
    "modal.viewDetail": "Xem chi tiết",
    "modal.removeWarningMessage": "Bạn có chắc chắn muốn xóa cái này không?",
    "modal.auditShop": "Kiểm tra cửa hàng",
    "modal.addLevel": "Thêm cấp độ",
    "modal.goodsOffOn": "Sản phẩm bật và tắt",
    "modal.banThisUser": "Bạn có muốn cấm người dùng này không?",
    "modal.remove": "Bạn có chắc chắn muốn gỡ bỏ nó?",
    "modal.acceptOrder": "Bạn có chắc chắn muốn chấp nhận đơn hàng này?",
    "modal.messageConfirmCloseStore":
        "* Sau hành động này, cửa hàng sẽ bị đóng, và tất cả các sản phẩm của nó sẽ không còn được hiển thị. Hành động này không thể hoàn tác. Người dùng cần đăng ký lại cửa hàng. Bạn có chắc chắn muốn đóng cửa hàng này không?",
    "modal.areYouSureToSetThisPostToFixedTopDiscoveryHomePage":
        "Bạn có chắc chắn muốn đặt bài đăng này lên đầu trang chủ khám phá không?",
    "modal.areYouSureToSetThisNewsToFixedTopDiscoveryHomePage":
        "Bạn có chắc chắn muốn đặt tin tức này lên đầu trang chủ khám phá không?",
    "modal.areYouSureToSetThisVideoToFixedTopDiscoveryHomePage":
        "Bạn có chắc chắn muốn đặt video này lên đầu trang chủ khám phá không?",
    "modal.areYouSureToSetThisTravelToFixedTopDiscoveryHomePage":
        "Bạn có chắc chắn muốn đặt bài viết du lịch này lên đầu trang chủ khám phá không?",
    are_u_clear_pay_pass:
        "Bạn có chắc chắn muốn xóa mật khẩu thanh toán không?",
};

export default {
    'client_info': "客户信息",
    'nickname': '昵称',
    'mobile': '移动的',
    'product_info': '产品信息',
    'original_price': '原价',
    'express': '表达',
    'refund_date': '退款日期',
    'quantity': '数量',
    'product_refund': '产品退款',
    'amount_refund': '退款金额',
    'province': '省',
    'district': '区',
    'address': '地址',
    'receiver': '接收者',
    'region': '地区',
    'description': '描述',
    'appeal_date': '上诉日期',
    'amount_rebate': '金额回扣',
    "delivery_charge": "送货费",
    "address_location_info": "地址位置信息",
    "address_location": "地址位置",
    "user_detail": "用户信息",
    "team_detail": "团队信息",
    "balance": "余额",
    "point": "积分",
    "income": "收益",
    "invest": "投资",
    "total_member": "总成员",
    "total_assets": "总团队资产",
    "total_income": "总推荐收益",
    "total_today_income": "今日推荐收益",
    "pay_password": "支付密码",
    "total_user_team": "团队",
    "total_recharge_rmb": "充值金额 (RMB)",
    "total_recharge_usd": "充值金额 (USD)",
    "total_recharge_jpy": "充值金额 (JPY)",
    "total_recharge_krw": "充值金额 (KRW)",
    "total_recharge_mnt": "充值金额 (MNT)",
    "total_recharge_vnd": "充值金额 (VND)",
    "total_recharge_eur": "充值金额 (EUR)",
    "total_recharge_usdt": "充值金额 (USDT)",
    "total_user_register": "注册人数",
    "total_user_register_7day": "注册人数 7天",
    "total_user_register_15day": "注册人数 15天",
    "total_invest_contract_30day": "投资金额 (30天)",
    "total_invest_contract_90day": "投资金额 (90天)",
    "total_invest_contract_180day": "投资金额 (180天)",
    "total_invest_contract_270day": "投资金额 (270天)",
    "total_invest_contract_360day": "投资金额 (360天)",
    "total_invest_contract_720day": "投资金额 (720天)",
    "total_invest_amount": "总投资额",
    "total_invest_amount_7day": "7天新增投资额",
    "total_invest_amount_15day": "15天新增投资额",
    "total_with_draw_amount": "总内提现",
    "total_with_draw_amount_7day": "7天内提现",
    "total_with_draw_amount_15day": "15天内提现",
    "total_user_team_number": "{{amount}}天内团队",
    "total_recharge_rmb_number": "{{amount}}天内充值金额 (RMB)",
    "total_recharge_usd_number": "{{amount}}天内充值金额 (USD)",
    "total_recharge_jpy_number": "{{amount}}天内充值金额 (JPY)",
    "total_recharge_krw_number": "{{amount}}天内充值金额 (KRW)",
    "total_recharge_mnt_number": "{{amount}}天内充值金额 (MNT)",
    "total_recharge_vnd_number": "{{amount}}天内充值金额 (VND)",
    "total_recharge_eur_number": "{{amount}}天内充值金额 (EUR)",
    "total_recharge_usdt_number": "{{amount}}天内充值金额 (USDT)",
    "total_user_register_number": "{{amount}}天内注册人数",
    "total_invest_contract_30day_number": "{{amount}}天内投资金额 (30天)",
    "total_invest_contract_90day_number": "{{amount}}天内投资金额 (90天)",
    "total_invest_contract_180day_number": "{{amount}}天内投资金额 (180天)",
    "total_invest_contract_270day_number": "{{amount}}天内投资金额 (270天)",
    "total_invest_contract_360day_number": "{{amount}}天内投资金额 (360天)",
    "total_invest_contract_720day_number": "{{amount}}天内投资金额 (720天)",
    "total_invest_amount_number": "{{amount}}天内投资额",
    "total_with_draw_amount_number": "{{amount}}天内提现",
    "total_user_team_from": "团队 (从 {{start}} 到 {{end}})",
    "total_recharge_rmb_from": "充值金额 (RMB) (从 {{start}} 到 {{end}})",
    "total_recharge_usd_from": "充值金额 (USD) (从 {{start}} 到 {{end}})",
    "total_recharge_jpy_from": "充值金额 (JPY) (从 {{start}} 到 {{end}})",
    "total_recharge_krw_from": "充值金额 (KRW) (从 {{start}} 到 {{end}})",
    "total_recharge_mnt_from": "充值金额 (MNT) (从 {{start}} 到 {{end}})",
    "total_recharge_vnd_from": "充值金额 (VND) (从 {{start}} 到 {{end}})",
    "total_recharge_eur_from": "充值金额 (EUR) (从 {{start}} 到 {{end}})",
    "total_recharge_usdt_from": "充值金额 (USDT) (从 {{start}} 到 {{end}})",
    "total_user_register_from": "注册人数 (从 {{start}} 到 {{end}})",
    "total_invest_contract_30day_from": "投资金额 (30天) (从 {{start}} 到 {{end}})",
    "total_invest_contract_90day_from": "投资金额 (90天) (从 {{start}} 到 {{end}})",
    "total_invest_contract_180day_from": "投资金额 (180天) (从 {{start}} 到 {{end}})",
    "total_invest_contract_270day_from": "投资金额 (270天) (从 {{start}} 到 {{end}})",
    "total_invest_contract_360day_from": "投资金额 (360天) (从 {{start}} 到 {{end}})",
    "total_invest_contract_720day_from": "投资金额 (720天) (从 {{start}} 到 {{end}})",
    "total_invest_amount_from": "总投资额 (从 {{start}} 到 {{end}})",
    "total_with_draw_amount_from": "总内提现 (从 {{start}} 到 {{end}})",
}
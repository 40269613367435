export default {
    wait_review: "Đang chờ duyệt",
    review_fail: "Duyệt thất bại",
    review_scc: "Duyệt thành công",
    wait_return: "Đang chờ trả lại",
    return_fail: "Trả lại thất bại",
    return_succ: "Trả lại thành công",
    wait_refund: "Đang chờ hoàn tiền",
    refund_fail: "Hoàn tiền thất bại",
    refund_succ: "Hoàn tiền thành công",
    refund: "Hoàn tiền",
    pending: "Đang chờ xử lý",
    checked: "Đã kiểm tra",
    responded: "Đã phản hồi",
    feedback: "Phản hồi",
    complain: "Khiếu nại",
    good_review: "Đánh giá tốt",
    about_app: "Về ứng dụng",
    running: "ĐANG CHẠY",
    finished: "ĐÃ HOÀN THÀNH",
    app: "ỨNG DỤNG",
    shipping: "ĐANG GIAO HÀNG",
    orderRebate: "Đang hoàn tiền",
    orderSuccess: "Xử lý hoàn tất",
    orderWaitDelivery: "Đang chờ giao hàng",
    orderWaitUserPicked: "Đang chờ người dùng đến lấy",
    orderWaitReceived: "Đang chờ nhận hàng",
    orderRefund: "Hoàn tiền",
    confirmed: "ĐÃ XÁC NHẬN",
    rejected: "BỊ TỪ CHỐI",
};

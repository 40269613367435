import { RangePickerProps } from "antd/lib/date-picker";

// 匿名函数调用
export const callFn = <T>(fn: () => T) => fn()

export const sleep = (ms: number) => new Promise((resolve) => {
  setTimeout(resolve, ms)
})

export const disabledPastDate: RangePickerProps['disabledDate'] = (current) => {
  // Can not select days before today and today
  return current && current.valueOf() < Date.now();
};

export const filterRequestParam = (params: any): any => {
  for (var propName in params) {
    if (params[propName] === null || params[propName] === undefined ||  params[propName]==='') {
      delete params[propName];
    }
  }
  return params
}

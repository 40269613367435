import { Outlet } from "react-router-dom";
import { createPage, useRouter } from "@qsh/base";
import React, { ReactNode, useEffect, useState } from "react";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
  BellOutlined,
} from "@ant-design/icons";
import { Layout, Menu, Dropdown, MenuProps, Card } from "antd";
import { useLocation } from "react-router-dom";
import { clearCurrentUser, getCurrentUser } from "./user";
import {
  AppProvider,
  MenuInfo,
  setCurrentRouter,
  useAppContext,
} from "./hooks";
import { DesktopOutlined } from "@ant-design/icons";
import { NamedIcon } from "@qsh/icon";

const { Header, Sider, Content } = Layout;

const locales = {
  en: {
    label: "English",
    icon: "🇺🇸",
  },
  zh: {
    label: "中文",
    icon: "🇨🇳",
  },
  vi: {
    label: "Tiếng Việt",
    icon: "🇻🇳",
  },
};

type CenterLayoutProps = {
  emptyLayoutPathList?: string[];
  headerTitle?: ReactNode;
  app_name: ReactNode;
  logout: ReactNode;
  requestMenu: () => Promise<MenuInfo[]>;
  changeLanguage: (lng?: string) => {};
  locale: string;
  shopNotification?: ReactNode;
};

export const CenterLayout = ({
  emptyLayoutPathList = ["/login", "/"],
  headerTitle,
  app_name,
  logout,
  requestMenu,
  changeLanguage,
  shopNotification,
}: CenterLayoutProps) => {
  let router = useRouter();
  setCurrentRouter(router);
  const location = useLocation();
  const user = getCurrentUser();
  let { menus, menuMap, collapsed, setCollapsed, menuPath, setMenus, appRef } =
    useAppContext();
  // init context
  appRef.current.requestMenu = requestMenu;

  const [openKeys, setOpenKeys] = useState(() => {
    return menuPath ? menuPath.map((v) => v.id + "").slice(0, -1) : [];
  });
  // 如果 openKeys 为空，menuPath长度大于1，则设置openKeys为menuPath的第一个元素
  if (openKeys?.length === 0 && menuPath?.length > 1) {
    setOpenKeys([menuPath[0].id + ""]);
  }
  const findChildren = (id: number) => {
    return menus
      ? menus.filter((item) => item.pid === id && item.status == "ON")
      : [];
  };

  const findMenu = (id: any) => {
    return menus?.find((item) => item.id == id);
  };

  const buildMenu = (pid: number): any[] => {
    let children = findChildren(pid);
    if (children.length === 0) {
      return undefined;
    }
    return children.map((v) => {
      return {
        label: v.name,
        key: v.id,
        path: v.path,
        icon: <NamedIcon name={v.icon} />,
        children: buildMenu(v.id),
        sort_num: v.sort_num,
      };
    });
  };

  // 空layout路由path
  if (emptyLayoutPathList.includes(location.pathname)) {
    return <Outlet />;
  }

  const selectedKeys = menuPath?.map((v) => v.id + "")?.pop();
  const needRedirect = () => {
    return (
      location.pathname === "/" &&
      selectedKeys !== undefined &&
      !menus.find((v) => v.path === "/") &&
      menuMap[selectedKeys]
    );
  };

  // redirect if account admin no permission
  if (menus.length > 0) {
    const pathMenus = menus.map((v) => v.path);
    // Split the pathname into segments
    const segments = location.pathname.split("/");

    // Get the first two segments and join them back together
    const firstTwoSegments = segments.slice(1, 3).join("/");

    if (firstTwoSegments != "" && firstTwoSegments != "/") {
      const doesContain = pathMenus.some((item) =>
        item.includes(firstTwoSegments)
      );

      if (!doesContain) {
        router.push({ path: pathMenus[0] });
      }
    }
  }

  if (needRedirect()) {
    router.push({
      path: menuMap[selectedKeys].path,
      replace: true,
    });
    return null;
  }

  const languages: MenuProps["items"] = [];
  Object.keys(locales).forEach((key) => {
    languages.push({
      key: key,
      label: (
        <span
          onClick={() => {
            changeLanguage(key);
            localStorage.setItem("locale", key);
          }}
        >
          <span style={{ marginRight: "8px" }}>
            {locales[key as "en"].icon}
          </span>
          {locales[key as "en"].label}
        </span>
      ),
    });
  });

  return (
    <Layout>
      <Sider
        className={"vh100 bg-white"}
        trigger={null}
        collapsible
        collapsed={collapsed}
      >
        {!collapsed && (
          <div
            className="flex shadow-box align-center pl24"
            style={{ height: 64 }}
          >
            {app_name}
          </div>
        )}

        {collapsed && (
          <div
            className="flex justify-center shadow-box align-center"
            style={{ height: 64 }}
          >
            <DesktopOutlined />
          </div>
        )}

        <Menu
          selectedKeys={[selectedKeys]}
          openKeys={openKeys}
          style={{
            height: "calc(100vh -  90px)",
            overflowY: "auto",
            overflowX: "hidden",
          }}
          theme="light"
          mode="inline"
          onClick={({ key }) => {
            router.push({ path: findMenu(key)?.path });
          }}
          onOpenChange={setOpenKeys}
          defaultSelectedKeys={["1"]}
          items={buildMenu(0)?.sort((a, b) =>
            a.sort_num > b.sort_num ? 1 : -1
          )}
        />
      </Sider>
      <Layout>
        <Header
          style={{ padding: 0 }}
          className="flex bg-white shadow-box space-between"
        >
          <div>
            <span
              className="ml16"
              onClick={() => {
                setCollapsed(!collapsed);
              }}
            >
              {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            </span>
            <span>{headerTitle}</span>
          </div>
          <div className="flex items-center justify-center grey6 mr24 space-between">
            {shopNotification}
            <Dropdown menu={{ items: languages }} className="mr16">
              <span className="cursor-pointer f20">
                <svg
                  viewBox="0 0 24 24"
                  focusable="false"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path
                    d="M12.87 15.07l-2.54-2.51.03-.03c1.74-1.94 2.98-4.17 3.71-6.53H17V4h-7V2H8v2H1v1.99h11.17C11.5 7.92 10.44 9.75 9 11.35 8.07 10.32 7.3 9.19 6.69 8h-2c.73 1.63 1.73 3.17 2.98 4.56l-5.09 5.02L4 19l5-5 3.11 3.11.76-2.04zM18.5 10h-2L12 22h2l1.12-3h4.75L21 22h2l-4.5-12zm-2.62 7l1.62-4.33L19.12 17h-3.24z "
                    className="css-c4d79v"
                  />
                </svg>
              </span>
            </Dropdown>
            <Dropdown
              menu={{
                items: [
                  {
                    label: logout,
                    key: "logout",
                    onClick: () => {
                      clearCurrentUser();
                      router.push({
                        path: "/",
                        replace: true,
                      });
                    },
                  },
                ],
              }}
            >
              <div>
                <UserOutlined />
                <span className="cursor-pointer ml8">{user?.nickname}</span>
              </div>
            </Dropdown>
          </div>
        </Header>
        <Content
          style={{
            height: "calc(100vh - 64px)",
            overflow: "auto",
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default {
    "common.menuInterface": "Giao diện Menu",
    "common.edit": "Chỉnh sửa",
    "common.enable": "Kích hoạt",
    "common.disable": "Vô hiệu",
    "common.displayOnMarquee": "Hiển thị trên Marquee",
    "common.removeOnMarquee": "Xóa khỏi Marquee",
    "common.displayOnPopup": "Hiển thị trên Popup",
    "common.removeOnPopup": "Xóa khỏi Popup",
    "common.interfaceList": "Danh sách giao diện",
    "common.assignMenu": "Gán menu",
    "common.newMenu": "Menu mới",
    "common.newRole": "Vai trò mới",
    "common.authorizationRole": "Phân quyền cho vai trò",
    "common.newUser": "Người dùng mới",
    "common.more": "Xem thêm",
    "common.changePassword": "Đổi mật khẩu",
    "common.audit": "Kiểm duyệt",
    "common.addNew": "Thêm mới",
    "common.manualRecharge": "Nạp tiền thủ công",
    "common.MANUALY": "MANUALY",
    "common.qpay": "QPAY",
    "common.goodsDetail": "Chi tiết sản phẩm",
    "common.viewDetail": "Xem chi tiết",
    "common.ban": "Cấm",
    "common.unban": "Bỏ cấm",
    "common.viewOrderFinance": "Xem đơn hàng tài chính",
    "common.viewOrderGoods": "Xem đơn hàng sản phẩm",
    "common.normal": "Bình thường",
    "common.abnormal": "Bất thường",
    "common.auditing": "Đang kiểm duyệt",
    "common.audited": "Đã kiểm duyệt",
    "common.auditFailed": "Kiểm duyệt thất bại",
    "common.bannerOn": "ON",
    "common.bannerOff": "OFF",
    "common.on": "On",
    "common.off": "Off",
    "common.chooseCategory": "Chọn danh mục",
    "common.bannerDelete": "Xóa banner",
    "common.okText": "Chấp nhận",
    "common.cancelText": "Hủy",
    "common.tip": "Gợi ý",
    "common.messageSuccess": "{{action}} thành công",
    "common.unbind": "Hủy liên kết",
    "common.operation": "Thao tác",
    "common.created": "Tạo mới",
    "common.updated": "Cập nhật",
    "common.deleted": "Xóa",
    "common.HOME": "TRANG CHỦ",
    "common.OTHER": "KHÁC",
    "common.PROMOTION": "KHUYẾN MÃI",
    "common.WALLET": "VÍ",
    "common.MEMBER": "THÀNH VIÊN",
    "common.EXTERNAL": "NGOẠI VI",
    "common.MERCHANT_APPLY": "ĐĂNG KÝ MERCHANT",
    "common.GOODS": "SẢN PHẨM",
    "common.MERCHANT": "MERCHANT",
    "common.delete": "Xóa",
    "common.sort": "Sắp xếp",
    "common.PASS": "PASS",
    "common.REJECT": "REJECT",
    "common.STOP": "STOP",
    "common.OPEN": "OPEN",
    "common.muteComment": "Mute",
    "common.levelOne": "Cấp một",
    "common.levelTwo": "Cấp hai",
    "common.levelThree": "Cấp ba",
    "common.memberRebateSetting":
        "Thiết lập tỷ lệ hoàn tiền cho thành viên đại lý",
    "common.goodsRebateSetting":
        "Thiết lập tỷ lệ hoàn tiền hàng hóa của đại lý thành viên",
    "common.memberAgentLevelSetting": "Cài đặt cấp độ đại lý thành viên",
    "common.addGrade": "Thêm cấp độ",
    "common.WAIT_PAY": "WAIT PAY",
    "common.SUCCEED": "Thành công",
    "common.manage": "Quản lý",
    "common.directRewardSetting": "Cài đặt phần thưởng giới thiệu trực tiếp",
    "common.newUserRewardSettings": "New user reward settings",
    "common.purchaseMembershipRewardSettings":
        "Purchase membership reward settings",
    "common.WAIT": "CHỜ ĐỢI",
    "common.FAIL": "THẤT ​​BẠI",
    "common.reject": "Từ chối",
    "common.pass": "Vượt qua",
    "common.UNPUBLISHED": "CHƯA XUẤT BẢN",
    "common.OFF": "TẮT",
    "common.ON": "BẬT",
    "common.buyVirtualGoodsRebateSettings": "Mua tỷ lệ hoàn tiền hàng hóa ảo",
    "common.depositInterestRebateSettings": "Tỷ lệ hoàn lãi tiền gửi",
    "common.INCOME": "THU NHẬP",
    "common.EXPEND": "CHI TIÊU",
    "common.REBATE": "HẠ GIÁ",
    "common.SHOP_MENU": "MENU CỬA HÀNG",
    "common.FINANCIAL_PRODUCT": "SẢN PHẨM TÀI CHÍNH",
    "common.COMMUNITY": "CỘNG ĐỒNG",
    "common.FINANCIAL_PRODUCT_REWARD": "THƯỞNG SẢN PHẨM TÀI CHÍNH",
    "common.REBATE_COMMISSION": "HOA HỒNG HẠ GIÁ",
    "common.WITHDRAW": "RÚT TIỀN",
    "common.WITHDRAW_ROLLBACK": "HOÀN LẠI RÚT TIỀN",
    "common.RECHARGE": "NẠP TIỀN",
    "common.INTEREST": "LÃI SUẤT",
    "common.INTEREST_CAPITAL": "TIỀN LÃI",
    "common.INTEREST_COMMISSION": "HOA HỒNG LÃI SUẤT",
    "common.OPEN_SHOP": "MỞ CỬA HÀNG",
    "common.CANCEL_SHOP": "HỦY CỬA HÀNG",
    "common.SHOP_TRANSFER": "CHUYỂN CỬA HÀNG",
    "common.BUY_ADVERTISEMENT": "MUA QUẢNG CÁO",
    "common.BUY_FINANCIAL": "MUA TÀI CHÍNH",
    "common.BUY_GOODS": "MUA HÀNG HÓA",
    "common.BUY_VIP": "MUA VIP",
    "common.BUY_VIP_COMMISSION": "MUA HOA HỒNG VIP",
    "common.COMMISSION_TRANSFER": "CHUYỂN HOA HỒNG",
    "common.COMMISSION_TRANSFER_INCOME_SCORE":
        "CHUYỂN HOA HỒNG TỪ ĐIỂM THU NHẬP",
    "common.COMMISSION_TRANSFER_SCORE": "CHUYỂN HOA HỒNG SANG ĐIỂM",
    "common.COMMISSION_TRANSFER_INCOME_BALANCE":
        "CHUYỂN HOA HỒNG TỪ SỐ DƯ THU NHẬP",
    "common.COMMISSION_TRANSFER_BALANCE": "CHUYỂN HOA HỒNG SANG SỐ DƯ",
    "common.BEAN_TASK_REWARD": "HOÀN THÀNH NHIỆM VỤ",
    "common.FUN_SPORT_REWARD": "HOÀN THÀNH NHIỆM VỤ FUNSPORT",
    "common.FUN_TASK_REWARD": "HOÀN THÀNH NHIỆM VỤ FUNTASK",
    "common.BEAN_TRANSFER": "CHUYỂN ĐẬU",
    "common.BEAN_CLAIM_TASK": "SỬ DỤNG ĐẬU THAM GIA NHIỆM VỤ",
    "common.BEAN_CANCEL_TASK": "NHẬN LẠI ĐẬU TỪ NHIỆM VỤ ĐÃ HỦY",
    "common.SALE_GOODS": "BÁN HÀNG HÓA",
    "common.BUY_BEAN": "MUA ĐẬU",
    "common.OLD_SYSTEM_RETURN": "TRẢ LẠI HỆ THỐNG CŨ",
    "common.SCORE_REWARD": "THƯỞNG ĐIỂM TỪ MUA VIP",
    "common.SYSTEM_REWARD": "THƯỞNG HỆ THỐNG",
    "common.ORDER_GOODS_REFUND": "HOÀN LẠI HÀNG HÓA",
    "common.STOCK": "CỔ PHIẾU",
    "common.FUN_SPORT": "THỂ THAO VUI",
    "common.FUNTASK": "NHIỆM VỤ VUI",
    "common.ORDER_LIST": "DANH SÁCH ĐẶT HÀNG",
    "common.yes": "Có",
    "common.no": "Không",
    "common.banEndTime": "Thời gian kết thúc cấm",
    "common.GOODS_DETAIL": "CHI TIẾT HÀNG HÓA",
    "common.choose_flag": "Chọn cờ",
    "common.reset": "Đặt lại",
    "common.submit": "Gửi",
    "common.confirmed": "Đã xác nhận",
    "common.HOME_POPUP": "CỬA SỔ BẬT LÊN TRANG CHỦ",
    "common.CAROUSELSHOP": "Shop Carousel",
    "common.CAROUSEL": "VÒNG XOAY",
    "common.HOME_BANNER": "BIỂU NGỮ TRANG CHỦ",
    "common.SETTING_BANNER": "BIỂU NGỮ CÀI ĐẶT",
    "common.KING_KONG": "KING KONG",
    "common.totalRegistration": "Tổng số đăng ký",
    "common.totalUserBuyVIP": "Tổng số người mua VIP",
    "common.totalRechargeAmount": "Tổng số tiền nạp (₮)",
    "common.totalRechargeCoin": "(USDT) Tổng số tiền nạp",
    "common.totalRechargeDong": "(USDT) Tổng số tiền nạp (₮)",
    "common.totalBankWithdraw": "Tổng số rút tiền ngân hàng (₮)",
    "common.totalUSDTWithdrawCoin": "(USDT) Tổng số tiền rút",
    "common.totalUSDTWithdrawDong": "(USDT) Tổng số tiền rút (₮)",
    "common.SHOP_STATUS_PENDING": "ĐANG CHỜ",
    "common.SHOP_STATUS_FAIL": "THẤT BẠI",
    "common.SHOP_STATUS_UNPAID": "CHƯA THANH TOÁN",
    "common.SHOP_STATUS_CANCEL": "HỦY",
    "common.adminManagement": "Quản lý quản trị viên",
    "common.banForever": "Cấm vĩnh viễn",
    "common.ordinary": "Bình thường",
    "common.vip": "VIP",
    "common.statisticData": "Dữ liệu thống kê",
    "common.logout": "Đăng xuất",
    "common.BALANCE": "Số dư",
    "common.INCOME_BALANCE": "Số dư thu nhập",
    "common.SCORE": "Điểm",
    "common.INCOME_SCORE": "Điểm thu nhập",
    "common.BEAN": "Đậu",
    "common.clickHere": "Bấm vào đây",
    "common.password": "Mật khẩu",
    "common.confirmPassword": "Xác nhận mật khẩu",
    "common.successModify": "Thay đổi thành công",
    "common.assignRole": "Giao vai trò",
    "common.addRole": "Thêm vai trò",
    "common.refuse": "Từ chối",
    "common.refund": "Hoàn tiền",
    "common.goToRefund": "Đi đến hoàn tiền",
    "common.remove": "Gỡ bỏ",
    "common.cancel": "Hủy",
    "common.viewSales": "Xem doanh số",
    "common.viewProducts": "Xem sản phẩm",
    "common.updateDelivery": "Cập nhật giao hàng",
    "common.set_default": "Đặt làm mặc định",
    "common.buy_member_reward_as_score": "Mua phần thưởng thành viên bằng điểm",
    "common.PAID": "ĐÃ THANH TOÁN",
    "common.total_withdraw_success": "Tổng số rút thành công",
    "common.total_withdraw_waiting": "Tổng số rút đang chờ",
    "common.total_Recharge_success": "Tổng số nạp thành công",
    "common.total_income": "Tổng thu nhập",
    "common.total_expend": "Tổng chi tiêu",
    "common.note_create_task_configuration":
        "Để bật nhiệm vụ, vui lòng cung cấp giá trị trong các trường tương ứng. Để trống trường sẽ dẫn đến việc tắt nhiệm vụ.",
    "common.invite_friends": "Mời bạn bè",
    "common.visit_store": "Thăm cửa hàng",
    "common.watch_video": "Xem video",
    "common.answer_question": "Trả lời câu hỏi",
    "common.read_news": "Đọc tin tức",
    "common.visit_flash_sale": "Thăm bán hàng chớp nhoáng",
    "common.promote_links": "Chia sẻ với bạn bè",
    "common.bubble": "Phát hành bong bóng",
    "common.totalPeopleJoinTask": "Tổng số người tham gia nhiệm vụ",
    "common.totalScoreUserGet": "Tổng số điểm người dùng nhận được",
    "common.totalAmount": "Tổng số tiền",
    "common.export": "Xuất khẩu",
    "common.variation": "Biến thể",
    "common.update_image": "Cập nhật hình ảnh",
    "common.save": "Lưu",
    "common.add_parent_variation": "Thêm biến thể cha",
    "common.add_child_variation": "Thêm biến thể con",
    "common.add_variation": "Thêm biến thể",
    "common.edit_variation": "Chỉnh sửa biến thể",
    "common.edit_name": "Chỉnh sửa tên",
    "common.edit_value": "Chỉnh sửa giá trị",
    "common.approvalFailed": "Phê duyệt thất bại!",
    "common.rejectWithdraw": "Từ chối rút tiền",
    "common.INVEST": "ĐẦU TƯ",
    "common.INVEST_FINISH": "HOÀN THÀNH ĐẦU TƯ",
    "common.INVEST_USER_FINISH": "HOÀN THÀNH NGƯỜI DÙNG ĐẦU TƯ",
    "common.DEPOSIT": "TIỀN GỬI",
    "common.INVEST_INCOME": "THU NHẬP ĐẦU TƯ",
    "common.CLUB": "CÂU LẠC BỘ",
    "common.TEAM_SAME_LEVEL": "ĐỘI CÙNG CẤP",
    "common.TEAM_DIFF_LEVEL": "ĐỘI KHÁC CẤP",
    "common.RECOMMEND_DIRECT": "ĐỀ XUẤT TRỰC TIẾP",
    "common.RECOMMEND_INDIRECT": "ĐỀ XUẤT GIÁN TIẾP",
    "common.SIGN": "KÝ",
    "common.SHARE": "CHIA SẺ",
    "common.TASK": "NHIỆM VỤ",
    "common.INCOME_TRANSFER": "CHUYỂN THU NHẬP",
    "common.BALANCE_TRANSFER": "CHUYỂN SỐ DƯ",
    "common.ACCOUNT_BALANCE": "SỐ DƯ TÀI KHOẢN",
    "common.ACCOUNT_INCOME": "THU NHẬP TÀI KHOẢN",
    "common.WITHDRAW_CHARGE": "PHÍ RÚT TIỀN",
    "common.WITHDRAW_CHARGE_ROLLBACK": "HOÀN LẠI PHÍ RÚT TIỀN",
    "common.TRAVEL": "DU LỊCH",
    "common.SCORE_TRANSFER": "CHUYỂN ĐIỂM",
    "common.NO_PAY": "KHÔNG THANH TOÁN",
    "common.PAY_SUCCESS": "THANH TOÁN THÀNH CÔNG",
    "common.PAY_FAILED": "THANH TOÁN THẤT BẠI",
    "common.bsc": "BSC",
    "common.BSC": "BSC",
    "common.trc": "TRC",
    "common.TRC": "TRC",
    "common.AUDITING": "KIỂM TOÁN",
    "common.AUDIT_PASS": "KIỂM TOÁN THÀNH CÔNG",
    "common.AUDIT_NOT_PASS": "KIỂM TOÁN KHÔNG THÀNH CÔNG",
    "common.PAYED": "ĐÃ THANH TOÁN",
    "common.DEPOSIT_AUDITING": "KIỂM TOÁN",
    "common.DEPOSIT_SUCCESS": "THÀNH CÔNG",
    "common.DEPOSIT_FAIL": "THẤT BẠI",
    "common.rejectCashDeposit": "Từ chối gửi tiền mặt",
    "common.confirmCashDeposit": "Xác nhận gửi tiền mặt",
    "common.accept": "Chấp nhận",
    "common.back": "Quay lại",
    "common.setToFixedTopHomePageDiscovery":
        "Đặt làm cố định trên đầu trang chủ Discovery",
    "common.failToFixedTopHomePageDiscovery":
        "Không thể đặt làm cố định trên đầu trang chủ Discovery.",
    "common.updateBasic": "Cập nhật số lượt thích",
    "common.add": "Thêm",
    "common.vod.videos.create": "Tạo Video",
    "common.addByLink": "thêm video liên kết",
    "common.addByLocal": "thêm video địa phương",
    "common.upload": "tải lên",
    "common.supportSingleOrBatchUpload": "hỗ trợ tải lên đơn lẻ hoặc hàng loạt",
    "common.pendingSlice": "đang chờ phân mảnh",
    "common.slicing": "đang phân mảnh",
    "common.uploading": "đang tải lên phân mảnh",
    "common.removePlayUrlError": "loại bỏ url phát",
    "common.addNewVi": "Thêm phim Việt Nam mới",
};

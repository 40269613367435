import dataOverview from "./vi-VN/dataOverview";
import common from "./vi-VN/common";
import tableColumn from "./vi-VN/tableColumn";
import modal from "./vi-VN/modal";
import validation from "./vi-VN/validation";
import label from "./vi-VN/label";
import status from "./vi-VN/status";

export default {
    dataOverview,
    common,
    tableColumn,
    modal,
    validation,
    label,
    status,
};

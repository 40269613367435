export default {
    'client_info': "Client Information",
    'nickname': 'Nickname',
    'mobile': 'Mobile',
    'product_info': 'Product Information',
    'original_price': 'Original Price',
    'express': 'Express',
    'refund_date': 'Refund Date',
    'quantity': 'Quantity',
    'product_refund': 'Product Refund',
    'amount_refund': 'Amount Refund',
    'province': 'Province',
    'district': 'District',
    'address': 'Address',
    'receiver': 'Receiver',
    'region': 'Region',
    'description': 'Description',
    'appeal_date': 'Appeal Date',
    'amount_rebate': 'Amount Rebate',
    "delivery_charge": "Delivery Charge",
    "address_location_info": "Address Location Information",
    "address_location": "Address Location ",
    "user_detail": "User Information",
    "team_detail": "Team Information",
    "balance": "Balance",
    "point": "Point",
    "income": "Income",
    "invest": "Invest",
    "total_member": "Total Member",
    "total_assets": "Total Assets",
    "total_income": "Total Income",
    "total_today_income": "Total Today Income",
    "pay_password": "Pay Password",
    "total_user_team": "Team",
    "total_recharge_rmb": "Recharge amount (RMB)",
    "total_recharge_usd": "Recharge amount (USD)",
    "total_recharge_jpy": "Recharge amount (JPY)",
    "total_recharge_krw": "Recharge amount (KRW)",
    "total_recharge_mnt": "Recharge amount (MNT)",
    "total_recharge_vnd": "Recharge amount (VND)",
    "total_recharge_eur": "Recharge amount (EUR)",
    "total_recharge_usdt": "Deposit amount (USDT)",
    "total_user_register": "Number of registered people",
    "total_user_register_7day": "Number of registered people 7 days",
    "total_user_register_15day": "Number of registered people 15 days",
    "total_invest_contract_30day": "Investment amount (30 days)",
    "total_invest_contract_90day": "Investment amount (90 days)",
    "total_invest_contract_180day": "Investment amount (180 days)",
    "total_invest_contract_270day": "Investment amount (270 days)",
    "total_invest_contract_360day": "Investment amount (360 days)",
    "total_invest_contract_720day": "Investment amount (720 days)",
    "total_invest_amount": "Total investment",
    "total_invest_amount_7day": "New investment amount in 7 days",
    "total_invest_amount_15day": "New investment amount in 15 days",
    "total_with_draw_amount": "Total withdrawal",
    "total_with_draw_amount_7day": "Withdraw cash within 7 days",
    "total_with_draw_amount_15day": "Withdraw cash within 15 days",
    "total_user_team_number": "Team in {{amount}} days",
    "total_recharge_rmb_number": "Recharge amount (RMB) in {{amount}} days",
    "total_recharge_usd_number": "Recharge amount (USD) in {{amount}} days",
    "total_recharge_jpy_number": "Recharge amount (JPY) in {{amount}} days",
    "total_recharge_krw_number": "Recharge amount (KRW) in {{amount}} days",
    "total_recharge_mnt_number": "Recharge amount (MNT) in {{amount}} days",
    "total_recharge_vnd_number": "Recharge amount (VND) in {{amount}} days",
    "total_recharge_eur_number": "Recharge amount (EUR) in {{amount}} days",
    "total_recharge_usdt_number": "Deposit amount (USDT) in {{amount}} days",
    "total_user_register_number": "Number of registered people in {{amount}} days",
    "total_invest_contract_30day_number": "Investment amount (30 days) in {{amount}} days",
    "total_invest_contract_90day_number": "Investment amount (90 days) in {{amount}} days",
    "total_invest_contract_180day_number": "Investment amount (180 days) in {{amount}} days",
    "total_invest_contract_270day_number": "Investment amount (270 days) in {{amount}} days",
    "total_invest_contract_360day_number": "Investment amount (360 days) in {{amount}} days",
    "total_invest_contract_720day_number": "Investment amount (720 days) in {{amount}} days",
    "total_invest_amount_number": "Total investment in {{amount}} days",
    "total_with_draw_amount_number": "Total withdrawal in {{amount}} days",
    "total_user_team_from": "Team (from {{start}} to {{end}})",
    "total_recharge_rmb_from": "Recharge amount (RMB) (from {{start}} to {{end}})",
    "total_recharge_usd_from": "Recharge amount (USD) (from {{start}} to {{end}})",
    "total_recharge_jpy_from": "Recharge amount (JPY) (from {{start}} to {{end}})",
    "total_recharge_krw_from": "Recharge amount (KRW) (from {{start}} to {{end}})",
    "total_recharge_mnt_from": "Recharge amount (MNT) (from {{start}} to {{end}})",
    "total_recharge_vnd_from": "Recharge amount (VND) (from {{start}} to {{end}})",
    "total_recharge_eur_from": "Recharge amount (EUR) (from {{start}} to {{end}})",
    "total_recharge_usdt_from": "Deposit amount (USDT) (from {{start}} to {{end}})",
    "total_user_register_from": "Number of registered people (from {{start}} to {{end}})",
    "total_invest_contract_30day_from": "Investment amount (30 days) (from {{start}} to {{end}})",
    "total_invest_contract_90day_from": "Investment amount (90 days) (from {{start}} to {{end}})",
    "total_invest_contract_180day_from": "Investment amount (180 days) (from {{start}} to {{end}})",
    "total_invest_contract_270day_from": "Investment amount (270 days) (from {{start}} to {{end}})",
    "total_invest_contract_360day_from": "Investment amount (360 days) (from {{start}} to {{end}})",
    "total_invest_contract_720day_from": "Investment amount (720 days) (from {{start}} to {{end}})",
    "total_invest_amount_from": "Total investment (from {{start}} to {{end}})",
    "total_with_draw_amount_from": "Total withdrawal (from {{start}} to {{end}})",
}
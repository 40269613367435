import { ProColumns } from "@ant-design/pro-table";
import { ProListMetas } from "@ant-design/pro-components";
import { Modal } from "antd";
export const defineColumns = <T>(columns: ProColumns<any>[]): any => {
    return columns;
};

export const defineMetas = <T>(metas: ProListMetas): any => {
    return metas;
};

export const modalConfirm = (
    content: string,
    title = "Алдаа",
    okText = "батлах",
    cancelText = "Цуцлах"
) => {
    return new Promise((resolve) => {
        Modal.confirm({
            title,
            content,
            okText: okText,
            cancelText: cancelText,
            onOk: () => {
                resolve(true);
            },
            onCancel: () => {
                resolve(false);
            },
        });
    });
};

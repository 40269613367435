type Config = {
    app_id: string;
    environments: {
        [key: string]: {
            base: string;
            gateway: string;
            cdn?: string;
        };
    };
};

const config: Config = {
    app_id: "admin",
    environments: {
        dev: {
            gateway: "/api",
            base: "/",
            cdn: "http://127.0.0.1:10004/slice/getFile?path=",
        },
        test: {
            gateway: "http://8.217.178.131:10004",
            base: "/",
            cdn: "http://8.217.178.131:10004/slice/getFile?path=",
        },
        prod: {
            gateway: "https://api.admin.uee7.com",
            base: "/",
            cdn: "https://api.admin.uee7.com/slice/getFile?path=",
        },
        prod2: {
            gateway: "https://api.admin.oss.uee7.com",
            base: "/",
            cdn: "https://api.admin.oss.uee7.com/slice/getFile?path=",
        }
    },
};

export const getEnvironment = () => {
    //@ts-ignore
    return config.environments[process.env.APP_ENV];
};

export default config;

export default {
    "tableColumn.name": "Tên",
    "tableColumn.name_en": "Tên tiếng Anh",
    "tableColumn.name_vi": "Tên tiếng Việt",
    "tableColumn.flag": "Cờ",
    "tableColumn.menuPath": "Đường dẫn menu",
    "tableColumn.icon": "Biểu tượng",
    "tableColumn.state": "Trạng thái",
    "tableColumn.sort": "Sắp xếp",
    "tableColumn.publish_date": "Ngày xuất bản",
    "tableColumn.creationTime": "Thời gian tạo",
    "tableColumn.changeTime": "Thời gian thay đổi",
    "tableColumn.operation": "Hoạt động",
    "tableColumn.interfacePath": "Đường dẫn giao diện",
    "tableColumn.id": "ID",
    "tableColumn.username": "Tên người dùng",
    "tableColumn.nickname": "Biệt danh",
    "tableColumn.remark": "Ghi chú",
    "tableColumn.title": "Tiêu đề",
    "tableColumn.EN": "Tiếng Anh",
    "tableColumn.VN": "Tiếng Việt",
    "tableColumn.image": "Hình ảnh",
    "tableColumn.url": "Url",
    "tableColumn.type": "Loại",
    "tableColumn.description": "Mô tả",
    "tableColumn.description_en": "Mô tả tiếng Anh",
    "tableColumn.description_vi": "Mô tả tiếng Việt",
    "tableColumn.interestRate": "Lãi suất",
    "tableColumn.maximumInvestAmount": "Số tiền đầu tư tối đa",
    "tableColumn.minimumInvestAmount": "Số tiền đầu tư tối thiểu",
    "tableColumn.tradeNo": "Số giao dịch",
    "tableColumn.memberID": "ID thành viên",
    "tableColumn.memberName": "Tên thành viên",
    "tableColumn.userID": "ID người dùng",
    "tableColumn.userInfo": "Thông tin người dùng",
    "tableColumn.parentInfo": "Thông tin cha mẹ",
    "tableColumn.startTime": "Thời gian bắt đầu",
    "tableColumn.endTime": "Thời gian kết thúc",
    "tableColumn.freezeID": "ID đóng băng",
    "tableColumn.frozenAmount": "Số tiền bị đóng băng",
    "tableColumn.restFrozenAmount": "Số tiền còn lại bị đóng băng",
    "tableColumn.shopName": "Tên cửa hàng",
    "tableColumn.ShopInfo": "Thông tin cửa hàng",
    "tableColumn.ShopOwner": "Chủ cửa hàng",
    "tableColumn.Buyer": "Người mua",
    "tableColumn.Receiver": "Người nhận",
    "tableColumn.price": "Giá",
    "tableColumn.rebateAmount": "Số tiền hoàn lại",
    "tableColumn.stock": "Hàng tồn kho",
    "tableColumn.max_buy_limit": "Giới hạn mua tối đa",
    "tableColumn.buyCount": "Số lượng mua",
    "tableColumn.collectCount": "Số lượng thu thập",
    "tableColumn.publistTime": "Thời gian xuất bản",
    "tableColumn.createdUser": "Người tạo",
    "tableColumn.recommend": "Đề xuất",
    "tableColumn.logo": "Logo",
    "tableColumn.address": "Địa chỉ",
    "tableColumn.balance": "Số dư",
    "tableColumn.totalBalance": "Tổng số dư",
    "tableColumn.imageSizeRemark": "Ghi chú kích thước hình ảnh",
    "tableColumn.count": "Số lượng",
    "tableColumn.platform": "Nền tảng",
    "tableColumn.forceUpdate": "Cập nhật bắt buộc",
    "tableColumn.version": "Phiên bản",
    "tableColumn.member": "Thành viên",
    "tableColumn.mobile": "Di động",
    "tableColumn.inviteCode": "Mã mời",
    "tableColumn.invitedCode": "Mã được mời",
    "tableColumn.parentMobile": "Di động của cha mẹ",
    "tableColumn.level": "Cấp độ",
    "tableColumn.rechargeAmount": "Số tiền nạp",
    "tableColumn.withdrawAmount": "Số tiền rút",
    "tableColumn.avatar": "Hình đại diện",
    "tableColumn.buyGoodsCount": "Số lượng mua hàng",
    "tableColumn.registerTime": "Thời gian đăng ký",
    "tableColumn.thumbnail": "Hình thu nhỏ",
    "tableColumn.goods_image": "Hình ảnh sản phẩm",
    "tableColumn.position": "Vị trí",
    "tableColumn.content": "Nội dung",
    "tableColumn.parentCategory": "Danh mục cha",
    "tableColumn.openMerchant": "Mở thương nhân",
    "tableColumn.vipAmount": "Giá mua VIP",
    "tableColumn.coverPicture": "Ảnh bìa",
    "tableColumn.cover": "Bìa",
    "tableColumn.urlType": "Loại URL",
    "tableColumn.term": "Kỳ hạn",
    "tableColumn.productNum": "Số lượng sản phẩm",
    "tableColumn.productLimit": "Giới hạn sản phẩm",
    "tableColumn.directRecommendCount": "Số lượng đề xuất trực tiếp",
    "tableColumn.followCount": "Số lượng người theo dõi",
    "tableColumn.onlineTime": "Thời gian trực tuyến",
    "tableColumn.failReason": "Lý do từ chối",
    "tableColumn.publishTime": "Thời gian phát hành",
    "tableColumn.muteDay": "Ngày tắt tiếng",
    "tableColumn.directPushNum": "Số lượng đẩy trực tiếp",
    "tableColumn.subDirectPushNum": "Số lượng đẩy phụ",
    "tableColumn.directPushIncome": "Thu nhập đẩy trực tiếp",
    "tableColumn.subDirectPushIncome": "Thu nhập đẩy phụ",
    "tableColumn.totalIncome": "Tổng thu nhập",
    "tableColumn.userLevel": "Cấp độ người dùng",
    "tableColumn.agentLevel": "Cấp độ đại lý",
    "tableColumn.rebateRate": "Tỷ lệ hoàn tiền%",
    "tableColumn.levelOne": "Cấp một",
    "tableColumn.levelTwo": "Cấp hai",
    "tableColumn.levelThree": "Cấp ba",
    "tableColumn.terminal": "Thiết bị đầu cuối",
    "tableColumn.amount": "Số tiền",
    "tableColumn.financeProduct": "Tên sản phẩm tài chính",
    "tableColumn.color_code": "Mã màu",
    "tableColumn.creator": "Người tạo",
    "tableColumn.profitSettings": "Thiết lập lợi nhuận",
    "tableColumn.profitSettingsDescription":
        "(Giá trị này là tỷ lệ lợi nhuận của nền tảng, ví dụ 40%. Người dùng mua hàng trị giá 10000 nhân dân tệ, lợi nhuận của nền tảng là 4000 nhân dân tệ và lợi nhuận của cửa hàng là 60% 6000 nhân dân tệ)",
    "tableColumn.profitRatioModify": "Tỷ lệ chia sẻ lợi nhuận của nền tảng",
    "tableColumn.advertisementPriceDaily": "Giá quảng cáo hàng ngày",
    "tableColumn.advertisementConfiguration": "Cấu hình quảng cáo",
    "tableColumn.applicationShopCondition": "Điều kiện ứng dụng cửa hàng",
    "tableColumn.applicationShopConditionDescription":
        "(Các điều kiện phải được đáp ứng khi đăng ký cửa hàng)",
    "tableColumn.platformProfit": "Lợi nhuận nền tảng",
    "tableColumn.serviceCharge": "Phí dịch vụ",
    "tableColumn.originPrice": "Giá gốc",
    "tableColumn.bankName": "Ngân hàng",
    "tableColumn.bankCard": "Thẻ ngân hàng",
    "tableColumn.bankUser": "Người dùng ngân hàng",
    "tableColumn.fee": "Phí",
    "tableColumn.feeRate": "Tỷ lệ phí",
    "tableColumn.realAmount": "Số tiền thực",
    "tableColumn.updater": "Người cập nhật",
    "tableColumn.abstract": "Tóm tắt",
    "tableColumn.is_china": "Là sản phẩm Trung Quốc",
    "tableColumn.usdtAddress": "Địa chỉ USDT",
    "tableColumn.fundType": "Loại quỹ",
    "tableColumn.amountType": "Loại số tiền",
    "tableColumn.discountRate": "Tỷ lệ giảm giá",
    "tableColumn.AmounDay": "Ngày số tiền",
    "tableColumn.consumeBean": "Tiêu thụ hạt đậu vui",
    "tableColumn.rewardBean": "Thưởng hạt đậu vui",
    "tableColumn.dailyStep": "Bước hàng ngày yêu cầu",
    "tableColumn.minLiveScore": "Điểm hoạt động tối thiểu",
    "tableColumn.taskDays": "Số ngày nhiệm vụ",
    "tableColumn.canGetTimes": "Giới hạn nhận",
    "tableColumn.canGetTimesRemark": "(0 nghĩa là không giới hạn)",
    "tableColumn.sortNum": "Số sắp xếp",
    "tableColumn.terms_and_conditions": "Điều khoản và điều kiện",
    "tableColumn.mini_amount": "Số tiền tối thiểu",
    "tableColumn.amount_list": "Danh sách số tiền",
    "tableColumn.agent_level": "Cấp độ đại lý",
    "tableColumn.vipBenefit": "Lợi ích VIP",
    "tableColumn.vipBenefitEn": "Lợi ích VIP Tiếng Anh",
    "tableColumn.vipBenefitVn": "Lợi ích VIP Tiếng Việt",
    "tableColumn.directVipRecommendCount": "Số lượng đề xuất VIP trực tiếp",
    "tableColumn.shopOpen": "Mở cửa hàng",
    "tableColumn.bean": "Hạt đậu",
    "tableColumn.role": "Vai trò",
    "tableColumn.roleName": "Tên vai trò",
    "tableColumn.expire_flash_sale": "Giảm giá chớp nhoáng hết hạn",
    "tableColumn.goods": "Hàng hóa",
    "tableColumn.shop": "Cửa hàng",
    "tableColumn.trade_no": "Số giao dịch",
    "tableColumn.product_name": "Tên sản phẩm",
    "tableColumn.product_image": "Hình ảnh sản phẩm",
    "tableColumn.goods_price": "Giá sản phẩm",
    "tableColumn.buy_amount": "Số lượng mua",
    "tableColumn.paid_amount": "Số tiền đã thanh toán",
    "tableColumn.delivery_charge": "Phí giao hàng",
    "tableColumn.total_paid_amount": "Tổng số tiền đã thanh toán",
    "tableColumn.reason": "Lý do",
    "tableColumn.reason_change": "Thay đổi lý do",
    "tableColumn.status": "Trạng thái",
    "tableColumn.message": "Thông điệp",
    "tableColumn.created_at": "Ngày tạo",
    "tableColumn.updated_at": "Ngày cập nhật",
    "tableColumn.key": "Khóa",
    "tableColumn.value": "Giá trị",
    "tableColumn.social": "Xã hội",
    "tableColumn.finance_term": "Kỳ hạn tài chính",
    "tableColumn.return_term_no": "Số kỳ hạn hoàn trả",
    "tableColumn.total_interest": "Tổng lãi suất",
    "tableColumn.start_time": "Thời gian bắt đầu",
    "tableColumn.end_time": "Thời gian kết thúc",
    "tableColumn.balance_income_balance": "Số dư & Thu nhập số dư",
    "tableColumn.score_income_score": "Điểm & Thu nhập điểm",
    "tableColumn.vip_sub_users": "Người dùng VIP phụ",
    "tableColumn.question": "Câu hỏi",
    "tableColumn.answer_key": "Đáp án",
    "tableColumn.banner": "Biểu ngữ",
    "tableColumn.area": "Khu vực",
    "tableColumn.tags": "Thẻ",
    "tableColumn.tag": "Thẻ",
    "tableColumn.member_id": "ID thành viên",
    "tableColumn.secret_key": "Khóa bí mật",
    "tableColumn.en": "Tiếng Anh",
    "tableColumn.goods_name": "Tên sản phẩm",
    "tableColumn.goods_name_en": "Tên sản phẩm Tiếng Anh",
    "tableColumn.goods_num": "Số lượng",
    "tableColumn.order_status": "Trạng thái đơn hàng",
    "tableColumn.express_no": "Số theo dõi",
    "tableColumn.express_company": "Công ty vận chuyển",
    "tableColumn.service_charge": "Phí dịch vụ",
    "tableColumn.shopLogo": "Logo cửa hàng",
    "tableColumn.shopBanner": "Biểu ngữ cửa hàng",
    "tableColumn.shopId": "ID cửa hàng",
    "tableColumn.shopCountry": "Quốc gia cửa hàng",
    "tableColumn.shopMobile": "Số di động chủ cửa hàng",
    "tableColumn.shopAdress": "Địa chỉ cửa hàng",
    "tableColumn.shopArea": "Khu vực cửa hàng",
    "tableColumn.shopStatus": "Trạng thái cửa hàng",
    "tableColumn.shopTag": "Thẻ cửa hàng",
    "tableColumn.receiver": "Người nhận",
    "tableColumn.receiver_address": "Chi tiết địa chỉ",
    "tableColumn.receiver_province": "Tỉnh/Thành phố",
    "tableColumn.receiver_district": "Quận/Huyện",
    "tableColumn.receiver_region": "Vùng",
    "tableColumn.receiver_moible": "Di động người nhận",
    "tableColumn.shop_share_amount": "Số lượng chia sẻ cửa hàng",
    "tableColumn.used_bean": "Sử dụng đậu",
    "tableColumn.shop_name": "Tên cửa hàng",
    "tableColumn.reward_score": "Điểm thưởng",
    "tableColumn.total_score_user_can_get": "Tổng điểm người dùng có thể nhận",
    "tableColumn.total_score_user_claimed": "Tổng điểm người dùng đã nhận",
    "tableColumn.total_user_finish_task":
        "Tổng số người dùng hoàn thành nhiệm vụ",
    "tableColumn.total_user_claimed_score": "Tổng số điểm người dùng đã nhận",
    "tableColumn.available": "Có sẵn",
    "tableColumn.task_days": "Ngày nhiệm vụ",
    "tableColumn.configuration": "Cấu hình",
    "tableColumn.watch_video": "Xem video",
    "tableColumn.answer_question": "Trả lời câu hỏi",
    "tableColumn.news": "Tin tức",
    "tableColumn.flash_sale": "Giảm giá chớp nhoáng",
    "tableColumn.promote_links": "Liên kết quảng bá",
    "tableColumn.date": "Ngày",
    "tableColumn.invite_friends": "Mời bạn bè",
    "tableColumn.default": "Mặc định",
    "tableColumn.answer": "Câu trả lời",
    "tableColumn.option": "Tùy chọn",
    "tableColumn.fact": "Sự thật",
    "tableColumn.score": "Điểm",
    "tableColumn.notes": "Ghi chú",
    "tableColumn.visit_flash_sale": "Tham gia giảm giá chớp nhoáng",
    "tableColumn.num_user_can_get": "Người dùng có thể nhận",
    "tableColumn.expire_time": "Thời gian hết hạn",
    "tableColumn.release_time": "Thời gian phát hành",
    "tableColumn.total_sold": "Tổng đã bán",
    "tableColumn.total_earnings": "Tổng thu nhập",
    "tableColumn.product": "Sản phẩm",
    "tableColumn.sku": "SKU",
    "tableColumn.variations": "Biến thể",
    "tableColumn.parent_variation": "Biến thể cha",
    "tableColumn.original_price": "Giá gốc",
    "tableColumn.product_en": "Sản phẩm Tiếng Anh",
    "tableColumn.value_en": "Giá trị Tiếng Anh",
    "tableColumn.code": "Mã",
    "tableColumn.code_name": "Tên mã",
    "tableColumn.back_to_select": "Quay lại chọn",
    "tableColumn.add_new": "Thêm mới",
    "tableColumn.react_count": "Số lượng phản ứng",
    "tableColumn.charge_type": "Loại phí",
    "tableColumn.video_info": "Thông tin video",
    "tableColumn.video": "Video",
    "tableColumn.title_en": "Tiêu đề Tiếng Anh",
    "tableColumn.user_received_product": "Người dùng đã nhận sản phẩm",
    "tableColumn.user_come_to_get_product": "Người dùng có đến lấy sản phẩm?",
    "tableColumn.OrderInfo": "Thông tin đơn hàng",
    "tableColumn.sub_title": "Tiêu đề phụ",
    "tableColumn.contractNumber": "Số hợp đồng",
    "tableColumn.partyA": "Bên A",
    "tableColumn.partyB": "Bên B",
    "tableColumn.partyC": "Bên C",
    "tableColumn.partyD": "Bên D",
    "tableColumn.investmentAmount": "Số tiền đầu tư",
    "tableColumn.head": "Đầu",
    "tableColumn.country": "Quốc gia",
    "tableColumn.province": "Tỉnh",
    "tableColumn.registerNumber": "Số đăng ký",
    "tableColumn.email": "Email",
    "tableColumn.accountName": "Tên tài khoản",
    "tableColumn.accountNumber": "Số tài khoản",
    "tableColumn.branchName": "Tên chi nhánh",
    "tableColumn.signature": "Chữ ký",
    "tableColumn.cycleSelection": "Thời gian",
    "tableColumn.contractLanguage": "Ngôn ngữ hợp đồng",
    "tableColumn.statusContract": "Trạng thái hợp đồng",
    "tableColumn.PENDING": "Đang chờ",
    "tableColumn.SIGNED": "Đã ký",
    "tableColumn.FINISHED": "Hoàn thành",
    "tableColumn.PAID": "Đã thanh toán",
    "tableColumn.eContractAcceptAlertTitle":
        "Bạn có chắc chắn muốn chấp nhận hợp đồng này không?",
    "tableColumn.eDeleteContractAlertTitle": "Bạn có chắc chắn muốn xóa không?",
    "tableColumn.eContractAlertContent": "Hành động này không thể hoàn tác.",
    "tableColumn.chainName": "Tên chuỗi",
    "tableColumn.walletAddress": "Địa chỉ ví",
    "tableColumn.THIRTY_DAYS": "30 ngày",
    "tableColumn.NINETY_DAYS": "90 ngày",
    "tableColumn.phoneNumber": "Số điện thoại",
    "tableColumn.days": "Ngày",
    "tableColumn.city": "Thành phố",
    "tableColumn.paymentTime": "Thời gian thanh toán",
    "tableColumn.paidAmount": "Số tiền đã thanh toán",
    "tableColumn.paymentMethod": "Phương thức thanh toán",
    "tableColumn.paymentDate": "Ngày thanh toán",
    "tableColumn.paymentProof": "Chứng từ thanh toán",
    "tableColumn.referance": "Tham chiếu",
    "tableColumn.club": "Câu lạc bộ",
    "tableColumn.contactMethod": "Phương thức liên hệ",
    "tableColumn.contact": "Liên hệ",
    "tableColumn.point": "Điểm",
    "tableColumn.user": "Người dùng",
    "tableColumn.income": "Thu nhập",
    "tableColumn.invest_amount": "Số tiền đầu tư",
    "tableColumn.free_fee_withdraw_amount": "Số tiền rút miễn phí",
    "tableColumn.enable_level_grade": "Kích hoạt cấp độ",
    "tableColumn.valid": "Hợp lệ",
    "tableColumn.is_club_owner": "Là chủ câu lạc bộ",
    "tableColumn.parent": "Cha mẹ",
    "tableColumn.commission_rate": "Tỷ lệ hoa hồng",
    "tableColumn.direct_valid_member_count":
        "Số lượng thành viên hợp lệ trực tiếp",
    "tableColumn.team_invest_amount": "Số tiền đầu tư của đội",
    "tableColumn.agent_count": "Số lượng đại lý",
    "tableColumn.from_level": "Từ cấp độ",
    "tableColumn.to_level": "Đến cấp độ",
    "tableColumn.recharge_type": "Loại nạp tiền",
    "tableColumn.rate": "Tỷ lệ",
    "tableColumn.biz": "Kinh doanh",
    "tableColumn.order": "Đơn hàng",
    "tableColumn.company": "Công ty",
    "tableColumn.income_rate": "Tỷ lệ thu nhập",
    "tableColumn.invest_rule": "Quy tắc đầu tư",
    "tableColumn.contract": "Hợp đồng",
    "tableColumn.total_amount": "Tổng số tiền",
    "tableColumn.total_expend": "Tổng chi tiêu",
    "tableColumn.total_income": "Tổng thu nhập",
    "tableColumn.third_party_amount": "Số tiền bên thứ ba",
    "tableColumn.author": "Tác giả",
    "tableColumn.like": "Thích",
    "tableColumn.author_avatar": "Ảnh đại diện của tác giả",
    "tableColumn.task": "Nhiệm vụ",
    "tableColumn.location": "Vị trí",
    "tableColumn.rating": "Đánh giá",
    "tableColumn.passport": "Hộ chiếu",
    "tableColumn.nationality": "Quốc tịch",
    "tableColumn.birthday": "Ngày sinh",
    "tableColumn.expire_date": "Ngày hết hạn",
    "tableColumn.issue_date": "Ngày cấp",
    "tableColumn.travel": "Du lịch",
    "tableColumn.sort_num": "Số thứ tự",
    "tableColumn.recommend_for_home": "Đề xuất cho trang chủ",
    "tableColumn.recommend_free_travel": "Đề xuất du lịch miễn phí",
    "tableColumn.recommend_explore": "Đề xuất khám phá",
    "tableColumn.home": "Trang chủ",
    "tableColumn.free_travel": "Du lịch miễn phí",
    "tableColumn.explore": "Khám phá",
    "tableColumn.reward_ratio": "Tỷ lệ thưởng",
    "tableColumn.learn_seconds": "Giây học tập",
    "tableColumn.start_date": "Ngày bắt đầu",
    "tableColumn.travel_duration": "Thời gian du lịch",
    "tableColumn.travel_destination": "Điểm đến du lịch",
    "tableColumn.min_amount": "Số tiền tối thiểu",
    "tableColumn.max_amount": "Số tiền tối đa",
    "tableColumn.day_and_rate": "Ngày và Tỷ lệ thu nhập",
    "tableColumn.point_rate": "Tỷ lệ điểm",
    "tableColumn.travel_overview": "Tổng quan về du lịch",
    "tableColumn.travel_information": "Thông tin du lịch",
    "tableColumn.min_invest": "Đầu tư tối thiểu",
    "tableColumn.min_deposit": "Tiền gửi tối thiểu",
    "tableColumn.task_type": "Loại nhiệm vụ",
    "tableColumn.isInvest": "Có đầu tư",
    "tableColumn.established_date": "Ngày thành lập",
    "tableColumn.post_or_video": "Bài đăng hoặc Video",
    "tableColumn.post": "Bài đăng",
    "tableColumn.price_included": "Giá đã bao gồm",
    "tableColumn.price_excluded": "Giá chưa bao gồm",
    "tableColumn.registration_date": "Ngày đăng ký",
    "tableColumn.minimum_participants": "Số lượng tham gia tối thiểu",
    "tableColumn.invest_banner_article": "Ảnh bìa bài viết",
    "tableColumn.invest_article": "Bài viết",
    "tableColumn.coordinate": "Tọa độ",
    "tableColumn.total_investment": "Tổng số đầu tư",
    "tableColumn.lead_amount": "Số tiền dẫn đầu",
    "tableColumn.follow_up_amount": "Số tiền theo dõi",
    "tableColumn.dining": "Ăn uống",
    "tableColumn.accommodation": "Chỗ ở",
    "tableColumn.banner_article": "Ảnh bìa bài viết",
    "tableColumn.content_article": "Nội dung bài viết",
    "tableColumn.club_user_ids": "Người dùng câu lạc bộ",
    "tableColumn.traveler": "Khách du lịch",
    "tableColumn.travel_time": "Thời gian du lịch",
    "tableColumn.invite_code": "Mã mời",
    "tableColumn.total_assets": "Tổng tài sản",
    "tableColumn.fund": "Quỹ",
    "tableColumn.note": "Ghi chú",
    "tableColumn.customer_id": "ID khách hàng",
    "tableColumn.club_id": "ID câu lạc bộ",
    "tableColumn.biz_type": "Loại kinh doanh",
    "tableColumn.account_type": "Loại tài khoản",
    "tableColumn.chain_type": "Loại chuỗi",
    "tableColumn.passenger": "Hành khách",
    "tableColumn.background_image": "Hình nền",
    "tableColumn.currency_type": "Loại tiền tệ",
    "tableColumn.recharge_to_usdt_rate": "Tỷ giá",
    "tableColumn.deposit_amount": "Số tiền đặt cọc",
    "tableColumn.bank": "Ngân hàng",
    "tableColumn.usdt_amount": "Số tiền USDT",
    "tableColumn.deposit_image": "Hình ảnh đặt cọc",
    "tableColumn.2fa": "Mã 2FA",
    "tableColumn.service_charge_fee": "Phí",
    "tableColumn.audit_time": "Ngày kiểm toán",
    "tableColumn.search": "Tìm kiếm",
    "tableColumn.gender": "Giới tính",
    "tableColumn.reject_reason": "Lý do từ chối",
    "tableColumn.booking_id": "Mã đặt chỗ",
    "tableColumn.totalUSDTSuccess": "Tổng số USDT nạp thành công",
    "tableColumn.totalRechargeAmount": "Tổng số tiền nạp thành công",
    "tableColumn.totalUSDTAuditing": "Tổng số USDT chờ duyệt",
    "tableColumn.totalWithdrawAuditing": "Tổng số tiền rút chờ duyệt",
    "tableColumn.totalWithdrawAuditPass": "Tổng số tiền rút đã duyệt",
    "tableColumn.totalWithdrawPaid": "Tổng số tiền rút đã trả",
    "tableColumn.country_code": "Mã quốc gia",
    "tableColumn.country_name": "Tên quốc gia",
    "tableColumn.bank_card": "Thẻ ngân hàng",
    "tableColumn.symbol": "Ký hiệu tiền tệ",
    "tableColumn.currency_code": "Mã tiền tệ",
    "tableColumn.basic_like": "Tăng số lượt thích",
    "tableColumn.total_like": "Tổng số lượt thích",
    "tableColumn.wallet_address": "Địa chỉ ví",
    "tableColumn.recharge_origin_amount": "Số tiền gốc",
    "tableColumn.cash_deposit_rules": "Quy tắc đặt cọc",
    "tableColumn.cash_deposit_rule_desc": "Mô tả quy tắc đặt cọc",
    "tableColumn.update_rule_cash_deposit": "Cập nhật quy tắc đặt cọc",
    "tableColumn.company_name": "Tên công ty",
    "tableColumn.usdt_address": "Địa chỉ USDT",
    "tableColumn.amount_apply": "Số tiền đăng ký",
    "tableColumn.balance_fee": "Phí số dư",
    "tableColumn.point_fee": "Phí điểm",
    "tableColumn.is_internal": "Tài khoản nhân viên",
    "tableColumn.parent_id": "Mã cha",
    "tableColumn.copied": "Đã sao chép",
    "tableColumn.copy_fail": "Sao chép thất bại",
    "tableColumn.popup": "Cửa sổ bật lên",
    "tableColumn.marquee": "Chạy chữ",
    "tableColumn.vod_id": "Mã ID",
    "tableColumn.type_id": "Mã loại",
    "tableColumn.vod_name": "Tên",
    "tableColumn.vod_sub": "Phụ đề",
    "tableColumn.vod_en": "Tên tiếng Anh",
    "tableColumn.vod_status": "Trạng thái",
    "tableColumn.vod_letter": "Chữ cái",
    "tableColumn.vod_tag": "Thẻ",
    "tableColumn.vod_class": "Lớp",
    "tableColumn.vod_type": "Loại",
    "tableColumn.vod_pic": "Hình ảnh",
    "tableColumn.vod_actor": "Diễn viên",
    "tableColumn.vod_category": "Danh mục",
    "tableColumn.vod_director": "Đạo diễn",
    "tableColumn.vod_writer": "Biên kịch",
    "tableColumn.vod_blurb": "Lời quảng cáo",
    "tableColumn.vod_remarks": "Ghi chú",
    "tableColumn.vod_pubdate": "Ngày phát hành",
    "tableColumn.vod_year": "Năm",
    "tableColumn.vod_total": "Tổng số",
    "tableColumn.vod_serial": "Số serial",
    "tableColumn.vod_area": "Khu vực",
    "tableColumn.vod_lang": "Ngôn ngữ",
    "tableColumn.vod_version": "Phiên bản",
    "tableColumn.vod_state": "Trạng thái",
    "tableColumn.vod_author": "Tác giả",
    "tableColumn.vod_duration": "Thời lượng",
    "tableColumn.vod_time_hits": "Số lần xem",
    "tableColumn.vod_time_make": "Thời gian sản xuất",
    "tableColumn.vod_trysee": "Thử xem",
    "tableColumn.vod_content": "Nội dung",
    "tableColumn.vod_play_from": "Phát từ",
    "tableColumn.vod_play_url": "URL phát",
    "tableColumn.type_name": "Tên loại",
    "tableColumn.vod_time_str": "Thời gian",
    "tableColumn.vod_basic_info": "Thông tin cơ bản",
    "tableColumn.vod_basic_info_desc": "Nhập thông tin cơ bản về video.",
    "tableColumn.vod_content_detail": "Chi tiết nội dung",
    "tableColumn.vod_content_detail_desc":
        "Cung cấp thông tin chi tiết về nội dung.",
    "tableColumn.vod_media_statistic": "Thống kê phương tiện",
    "tableColumn.vod_media_statistic_desc":
        "Tải lên phương tiện và cung cấp số liệu thống kê.",
    "tableColumn.tmdbId": "TMDB ID",
    "tableColumn.doubanId": "Douban ID",
    "tableColumn.tmdb": "TMDB",
    "tableColumn.douban": "Douban",
    "tableColumn.server": "Máy chủ",
    "tableColumn.lang": "Ngôn ngữ",
    "tableColumn.season": "Mùa",
    "tableColumn.ad_segment": "Đoạn quảng cáo",
    "tableColumn.Url": "địa chỉ",
    "tableColumn.duration": "Thời lượng",
    "tableColumn.tmdb_id": "Mã TMDB",
    "tableColumn.adKey": "Khóa quảng cáo",
    "tableColumn.adPosition": "Vị trí quảng cáo",
    "tableColumn.adMax": "Phạm vi giá trị 0 ~ 1,0",
    "tableColumn.adMin": "Phạm vi giá trị 0 ~ 1,0",
    "tableColumn.adSecsPlaceholder": "Quảng cáo phát ở giây nào",
    "tableColumn.adPositionPlaceholder":
        "Phạm vi giá trị là 0~1,0 (0,5 có nghĩa là chèn quảng cáo vào giữa video)",
    "tableColumn.secs": "Quảng cáo thứ hai",
};
